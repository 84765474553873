import type { FormAddress, FormApplicantDetails } from './helpers';
export interface FormApplicant extends FormApplicantDetails {
  email?: string;
  mainPhone?: string;
  applicantType?: string;
  currentAddress?: FormAddress;
}

export type FormCoApplicant = Partial<FormApplicant>;

export const applicantDefaults: FormApplicant = {
  firstName: '',
  lastName: '',
  email: undefined,
  mainPhone: undefined,
  dateOfBirth: undefined,
  gender: undefined,
  maritalStatus: undefined,
  relation: undefined,
  education: undefined,
  employmentStatus: undefined,
  industry: undefined,
  occupation: undefined,
  currentAddress: {
    lineOne: undefined,
    lineTwo: undefined,
    city: undefined,
    stateCode: undefined,
    postalCode: undefined,
  },
  applicantType: undefined,
};

export const coApplicantDefaults: FormCoApplicant = {
  firstName: undefined,
  lastName: undefined,
  dateOfBirth: undefined,
  gender: undefined,
  maritalStatus: undefined,
  relation: undefined,
  education: undefined,
  employmentStatus: undefined,
  industry: undefined,
  occupation: undefined,
};
