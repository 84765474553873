import type { FC } from 'react';
import type { DefaultValuesMap } from '@/services/forms/agent/schema';
import type { FormPage } from '@/services/forms/models/formTypes';
import type { QuoteGroupWithQuote } from '@/services/forms/models/quoteTypes';
import Section from '../../Section';
import ActionMapper from '../Actions';
import { DynamicPanel } from '../DynamicPanel';
import { DynamicSection } from '../DynamicSection';
import { FormPanel } from '../FormPanel';
import { FormSection } from '../FormSection';
import { UseFormReturn } from 'react-hook-form';
import type { AgentIntakeForm } from '@/services/forms/agent/schema';

type Field = {
  display: string;
  page: string;
};

type SubModelVehicle = {
  year: string | undefined;
  make: string | undefined;
  model: string | undefined;
  vehicle_number: number | undefined;
};

interface FormPageProps {
  page: FormPage;
  defaultValuesMap?: DefaultValuesMap;
  searchResults?: Field[] | null;
  quoteGroups?: QuoteGroupWithQuote[];
  formMethods?: UseFormReturn<AgentIntakeForm>;
  setSubModelOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setSubModelVehicle?: React.Dispatch<
    React.SetStateAction<SubModelVehicle | undefined>
  >;
}

const FormPage: FC<FormPageProps> = ({
  page,
  defaultValuesMap,
  searchResults,
  quoteGroups,
  formMethods,
  setSubModelOpen,
  setSubModelVehicle,
}) => {
  const actions = page.actions ? (
    <div data-testid="rater-link-container" className="flex flex-row gap-2">
      {page.actions.map((action) => (
        <ActionMapper
          key={action.id}
          action={action}
          quoteGroup={quoteGroups?.find(
            (quoteGroup) =>
              quoteGroup.rater.toLowerCase() === action.type.toLowerCase() &&
              quoteGroup.insuranceProduct.toLowerCase() ===
                action.fieldName.toLowerCase()
          )}
        />
      ))}
    </div>
  ) : null;

  return (
    <Section
      headingText={page.display}
      materialIcon={page.icon}
      actions={actions}
    >
      {page.elements.map((element) => {
        if (element.type === 'dynamicPanel' && defaultValuesMap) {
          const defaults =
            defaultValuesMap[element.defaults as keyof DefaultValuesMap];
          return (
            <DynamicPanel
              panel={element}
              panelDefaults={defaults}
              searchResults={searchResults}
              key={element.id}
              formMethods={formMethods}
              setSubModelOpen={setSubModelOpen}
              setSubModelVehicle={setSubModelVehicle}
            />
          );
        }
        if (element.type === 'dynamicSection' && defaultValuesMap) {
          const defaults =
            defaultValuesMap[element.defaults as keyof DefaultValuesMap];
          return (
            <DynamicSection
              section={element}
              sectionDefaults={defaults}
              searchResults={searchResults}
            />
          );
        }
        if (element.type === 'panel') {
          return <FormPanel panel={element} searchResults={searchResults} />;
        }
        if (element.type === 'section') {
          return (
            <FormSection section={element} searchResults={searchResults} />
          );
        }
        return null;
      })}
    </Section>
  );
};

export { FormPage };
