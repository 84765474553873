import { FC } from 'react';
import Icon from '@/components/Icon';

export type ActionBannerProps = {
  bannerMessage: string;
  bannerActions: JSX.Element;
  showBanner: boolean;
};

const ActionBanner: FC<ActionBannerProps> = ({
  bannerMessage,
  bannerActions,
  showBanner,
}) => {
  if (!showBanner) {
    return null;
  }
  return (
    <div className="h-11 flex justify-between border border-light-borders-primary rounded-lg bg-light-surfaces-info gap-2 py-2 px-3">
      <div className="flex items-center gap-2">
        <Icon type={'sparkle_filled'} color={'text-primary'} />
        <div className="text-sm text-light-text-contrast">{bannerMessage}</div>
      </div>
      <div>{bannerActions}</div>
    </div>
  );
};

export { ActionBanner };
