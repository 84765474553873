import { FormApplicant } from '@/services/forms/agent/schema';
import {
  PrefillRequest,
  PrefillResponse,
} from '@/services/forms/models/prefillTypes';

export const prefillData = async ({
  data,
  userId = '',
  fetchFn,
}: {
  data: FormApplicant;
  userId?: string;
  fetchFn: typeof fetch;
}): Promise<PrefillResponse | null> => {
  const body: PrefillRequest = {
    requestor: {
      userId: userId,
      userSystem: 'dynamics',
      service: 'fusion-ui',
    },
    requests: [
      {
        target: 'auto',
        requestDetails: {
          applicant: {
            firstName: data.firstName,
            lastName: data.lastName,
            currentAddress: {
              lineOne: data.currentAddress?.lineOne as string,
              city: data.currentAddress?.city as string,
              postalCode: data.currentAddress?.postalCode as string,
              stateCode: data.currentAddress?.stateCode as string,
            },
          },
        },
      },
    ],
  };
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const url = `apim/prefill/v1/api/data`;
  const response = await fetchFn(url, requestOptions);
  
  if (!response.ok) {
    throw new Error('Failed to prefill data, please try again.');
  }

  return response.json();
};
