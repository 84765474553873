import { FC } from 'react';
import { Button } from '@/components/ui/button';

export type PrefillButtonProps = {
  handlePrefill: (e: React.MouseEvent<HTMLElement>) => void;
};

const PrefillButton: FC<PrefillButtonProps> = ({ handlePrefill }) => {
  return (
    <Button
      data-testid={'prefill-button'}
      className="w-[90px] bg-[#2C6DB7] gap-1 font-normal text-light-text-inverse py-1 pr-2 pl-2.5"
      onClick={handlePrefill}
    >
      Pre-fill data
    </Button>
  );
};

export { PrefillButton };
