import { ListOption } from '@/models/common/options';
import { Policy, PolicyLink } from '@/models/document/policy';
import { Opportunity, OpportunityLink } from '@/models/document/opportunity';
import { formatTerm } from '../common/utils';

const FILENAME_ALLOWED_CHAR_REGEX = /[^\w\s\d`'"._()\\-]/gi;

export const getCleanFileName = (value: string) => {
  return value.replace(FILENAME_ALLOWED_CHAR_REGEX, '');
};
export const buildOpportunitySelectHandler =
  (opportunityOptionList: ListOption[], callback: any) =>
  (clickedOpportunity: ListOption) => {
    const selectedOppIds: string[] = [];

    const oppOptionListCopy = [...opportunityOptionList];
    oppOptionListCopy.forEach((opportunity: ListOption) => {
      if (opportunity.value === clickedOpportunity.value) {
        opportunity.selected = !opportunity.selected;
      }
      if (opportunity.selected) {
        selectedOppIds.push(opportunity.value);
      }
    });
    const response = {
      opportunityOptionList: oppOptionListCopy,
      selectedOpportunityIds: selectedOppIds,
    };

    if (callback) {
      callback(response);
    }

    return response;
  };

export const buildPolicySelectHandler =
  (policyOptionList: ListOption[], callback?: any) =>
  (clickedPolicy: ListOption) => {
    const selectedPolicyIds: string[] = [];

    const policyOptionListCopy = [...policyOptionList];
    policyOptionListCopy.forEach((policy: ListOption) => {
      if (policy.value === clickedPolicy.value) {
        policy.selected = !policy.selected;
      }
      if (policy.selected) {
        selectedPolicyIds.push(policy.value);
      }
    });
    const response = {
      policyOptionList: policyOptionListCopy,
      selectedPolicyIds,
    };

    if (callback) {
      callback(response);
    }

    return response;
  };

export const buildCategorySelectHandler =
  (categoryOptionList: ListOption[], callback: any) =>
  (clickedCategory: ListOption) => {
    const categoryOptionListCopy = [...categoryOptionList];
    categoryOptionListCopy.forEach((category) => {
      if (category.label === clickedCategory.label) {
        category.selected = true;
      } else {
        category.selected = false;
      }
    });

    const response = {
      category: clickedCategory.label,
      categoryOptionList: categoryOptionListCopy,
    };

    if (callback) {
      callback(response);
    }

    return response;
  };

export const resetOptionListState = (prevState: ListOption[]) => {
  const optionListCopy = [...prevState];
  optionListCopy.forEach((option: ListOption) => {
    option.selected = false;
  });
  return optionListCopy;
};

export const getDeselectedPolicies = (
  incLinkedPolicyList: PolicyLink[],
  selectedPolicies: string[]
): string[] => {
  const deselectedPolicyIdList: string[] = [];

  incLinkedPolicyList.forEach((linkedPolicy: PolicyLink) => {
    if (!selectedPolicies.includes(linkedPolicy.policyId)) {
      deselectedPolicyIdList.push(linkedPolicy.policyId);
    }
  });

  return deselectedPolicyIdList;
};

export const getDeselectedOpportunities = (
  incLinkedOpportunityList: OpportunityLink[],
  selectedOpportunities: string[]
): string[] => {
  const deselectedOpportunityIdList: string[] = [];

  incLinkedOpportunityList.forEach((linkedOpportunity: OpportunityLink) => {
    if (!selectedOpportunities.includes(linkedOpportunity.opportunityId)) {
      deselectedOpportunityIdList.push(linkedOpportunity.opportunityId);
    }
  });

  return deselectedOpportunityIdList;
};
export const getFileNameFromFiles = (
  files: FileList,
  fileInputRef: React.RefObject<HTMLInputElement>
) => {
  const existingName = files?.[0]?.name;
  const newName = fileInputRef?.current?.files?.[0]?.name;
  if (newName) {
    fileInputRef.current.files = files;

    return newName;
  }

  return existingName;
};

export const getMappedPolicies = (policyList: Policy[]) =>
  policyList.map(
    (policy: Policy) =>
      ({
        label: `${policy.carrier.name} (${policy.policyNumber})`,
        subtext: `Term: ${formatTerm(
          policy.effectiveDate,
          policy.expirationDate
        )} | Status: ${policy.policyStatus}`,
        value: policy.policyId,
        groupByValue: new Date(policy.effectiveDate).getFullYear(),
      } as ListOption)
  );

export const getMappedOpportunities = (opportunityList: Opportunity[]) =>
  opportunityList.map(
    (opp: Opportunity) =>
      ({
        label: opp.name,
        value: opp.opportunityId,
      } as ListOption)
  );

export const getMappedCategories = (categoryList: string[]) =>
  categoryList.map((category: string) => {
    return {
      label: category,
    } as ListOption;
  });

export const getMappedSelectedPolicies = (
  allPolicyList: Policy[],
  selectedPolicies: string[]
) =>
  allPolicyList?.map(
    (policyItem: Policy) =>
      ({
        label: `${policyItem.carrier.name} (${policyItem.policyNumber})`,
        subtext: `Term: ${formatTerm(
          policyItem.effectiveDate,
          policyItem.expirationDate
        )} | Status: ${policyItem.policyStatus}`,
        value: policyItem.policyId,
        groupByValue: new Date(policyItem.effectiveDate).getFullYear(),
        selected: selectedPolicies.includes(policyItem.policyId),
      } as ListOption)
  );

export const getMappedSelectedOpportunities = (allOpportunityList: Opportunity[], selectedOpportunities: string[]) => allOpportunityList?.map(
  (opportunity: Opportunity) =>
    ({
      label: `${opportunity.name}`,
      value: opportunity.opportunityId,
      selected: selectedOpportunities.includes(opportunity.opportunityId),
    } as ListOption)
);
  
export const getMappedSelectedCategories = (categoryList: string[], category: string) => categoryList.map((categoryItem: string) => {
  return {
    label: categoryItem,
    selected: category === categoryItem,
  };
})

