import { UseFormReturn } from 'react-hook-form';
import type { AgentIntakeForm } from '@/services/forms/agent/schema';
import { PrefillResponse } from '@/services/forms/models/prefillTypes';
import { defaultValuesMap } from '@/services/forms/agent/schema';

const normalizeYear = (item: Record<string, any>) => {
  if (item.year) {
    item.year = String(item.year);
  }
};

const processItems = (
  items: Record<string, any> | undefined,
  defaultValues: Record<string, any>,
  appendFunction: (item: any) => void
) => {
  if (items) {
    Object.values(items).forEach((item: any) => {
      normalizeYear(item);
      appendFunction({ ...defaultValues, ...item });
    });
  }
};

export const populateAuto = (
  formMethods: UseFormReturn<AgentIntakeForm>,
  data: PrefillResponse | null,
  appendDriver: (driver: any) => void,
  appendVehicle: (vehicle: any) => void
) => {
  const results = data?.responses;

  if (results) {
    results.forEach((dataItem) => {
      if (dataItem.target === 'auto') {
        const { drivers, vehicles } = dataItem.data;

        processItems(drivers, defaultValuesMap['drivers'], appendDriver);
        processItems(vehicles, defaultValuesMap['vehicles'], appendVehicle);
      }
    });

    formMethods.setValue('drivers', formMethods.getValues('drivers'));
    formMethods.setValue('vehicles', formMethods.getValues('vehicles'));
  }
};
